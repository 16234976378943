import React, { useEffect, useRef, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import clsx from 'clsx';
import { Icon24UtilityMenu } from '@care/react-icons';
import { AnalyticsHelper } from '@/utilities/AnalyticsHelper';
import HamburgerMenu from '@/components/shared/HamburgerMenu/HamburgerMenu';
import { Layout, SxClassProps } from '@/types';
import { LAYOUTS, VERTICALS_NAMES } from '@/constants';
import { AnalyticsEventName, CtaLocation, CtaText, ScreenName } from '@/types/enums';

const classes: SxClassProps = {
  container: {
    display: { md: 'none' },
  },
  sandwichButton: (theme) => ({
    display: 'none',
    cursor: 'pointer',

    '& svg': {
      height: theme.spacing(3),
      width: theme.spacing(3),

      '& g': {
        fill: theme.palette.care.white,
        [theme.breakpoints.down('md')]: {
          fill: theme.palette.care.navy[800],
        },
      },
    },
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      padding: theme.spacing(0, 2, 0, 1),
    },

    '&.sandwich-button-value-drivers-layout': {
      [theme.breakpoints.down('lg')]: {
        display: 'flex',
        padding: theme.spacing(0, 2, 0, 1),
        marginLeft: '-10px',
      },
    },
  }),
};

type SEOMegaNavMWProps = {
  layout?: Layout;
};

const SEOMegaNavMW = ({ layout = LAYOUTS.default }: SEOMegaNavMWProps) => {
  const theme = useTheme();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const hamburgerIconSize = () => (!isSmall ? '32px' : '24px');

  const toggleMenuVisibility = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const amplitudeData = {
    screen_name: ScreenName.VISITOR_HOMEPAGE,
    enrollment_initiated: false,
    cta_location: CtaLocation.VHP_HEADER,
    cta_text: CtaText.INTERNAL_LINKING_MENU,
    vertical: VERTICALS_NAMES.unknown,
  };

  const handleMenuClick = () => {
    AnalyticsHelper.logEvent({
      name: AnalyticsEventName.CTA_INTERACTED_VHP,
      data: {
        ...amplitudeData,
        isMenuOpen: !isMenuOpen,
        label: 'SEO mega menu button click (opened)',
      },
    });
    toggleMenuVisibility();
  };

  const handleCloseMenuClick = () => {
    AnalyticsHelper.logEvent({
      name: AnalyticsEventName.CTA_INTERACTED_VHP,
      data: {
        ...amplitudeData,
        isMenuOpen: !isMenuOpen,
        label: 'SEO mega menu button click (closed)',
      },
    });
    toggleMenuVisibility();
  };

  const handleMenuKeyPress = (event: React.KeyboardEvent) => {
    if (event.keyCode === 13) {
      AnalyticsHelper.logEvent({
        name: AnalyticsEventName.CTA_INTERACTED_VHP,
        data: {
          ...amplitudeData,
          isMenuOpen: !isMenuOpen,
          label: 'SEO mega menu button click (opened)',
        },
      });
      toggleMenuVisibility();
    }
  };

  const dismissListener = (event: Event) => {
    if (!menuRef.current?.contains(event.target as Node)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener('click', dismissListener, true);
    }

    return () => document.removeEventListener('click', dismissListener, true);
  }, [isMenuOpen]);

  return (
    <Box sx={classes.container} ref={menuRef}>
      <Box
        role="button"
        data-testid="menu-hamburger-open-button"
        tabIndex={0}
        sx={classes.sandwichButton}
        className={clsx({
          'sandwich-button-value-drivers-layout': layout === LAYOUTS.valueDrivers,
        })}
        onClick={() => handleMenuClick()}
        onKeyPress={(event: React.KeyboardEvent) => handleMenuKeyPress(event)}>
        <Icon24UtilityMenu size={hamburgerIconSize()} />
      </Box>
      <HamburgerMenu isMenuOpen={isMenuOpen} onClose={handleCloseMenuClick} />
    </Box>
  );
};

export default SEOMegaNavMW;
