import { Box } from '@mui/material';
import { IconLogoCare } from '@care/react-icons';
import TopNavigationDW from '@/components/shared/HomePageTopNavigation/TopNavigationDW';
import TopNavigationMW from '@/components/shared/HomePageTopNavigation/TopNavigationMW';
import SEOMegaNavDT from '@/components/shared/SEOMegaNavDT';
import SEOMegaNavMW from '@/components/shared/SEOMegaNavMW';
import useResponsive from '@/components/hooks/useResponsive';
import { LAYOUTS } from '@/constants';
import { Layout, SxClassProps } from '@/types';

interface HeaderProps {
  layout: Layout;
  toggleModal?: () => void;
}

const getClasses = (layout: Layout): SxClassProps => ({
  root: [
    (theme) => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor:
        layout === LAYOUTS.valueDrivers || layout === LAYOUTS.rebranding
          ? theme.palette.care?.white
          : theme.palette.care?.grey[50],
      position: 'relative',
      maxWidth: layout === LAYOUTS.rebranding ? 'none' : '1440px',
      width: '100%',
      padding: {
        xs:
          layout === LAYOUTS.valueDrivers || layout === LAYOUTS.rebranding
            ? theme.spacing(2, 1.5)
            : theme.spacing(1, 3),
        sm: theme.spacing(
          layout === LAYOUTS.valueDrivers || layout === LAYOUTS.rebranding ? 3 : 4,
          4
        ),
      },
    }),
  ],
});

const HeaderWithSEOMegaNav = (props: HeaderProps) => {
  const { toggleModal, layout } = props;
  const { isDesktop } = useResponsive();
  const classes = getClasses(layout);

  return (
    <Box component="nav" sx={classes.root} data-testid="header">
      {isDesktop ? (
        <>
          <IconLogoCare height={32} width={202} />
          <TopNavigationDW verticalsView toggleModal={toggleModal} />
          <SEOMegaNavDT />
        </>
      ) : (
        <>
          <SEOMegaNavMW layout={layout} />
          <IconLogoCare height={18} width={116.625} data-testid="careLogo-mobile" />
          <TopNavigationMW verticalsView toggleModal={toggleModal} layout={layout} />
        </>
      )}
    </Box>
  );
};

export default HeaderWithSEOMegaNav;
